import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { SkeModal } from '../../../../common/modals/generic/SkeModal';
import { Recognition } from '../../../../features/recognition/interfaces/recognition.model';
import { recognitionModalSlice } from '../../../../features/recognition/recognition-modal.slice';
import { AvatarSize } from '../../../components/Avatar';
import { EmployeeBadge } from '../../../components/EmployeeBadge';
import { getNameFromLanguageAbbr } from '../../../modules/translation/services/Translation.service';
import { SkeInfoGrid } from '../../../../common/components/InfoGrid';
import { SkePopover } from '../../../../common/components/Popover';
import { parseDateObjectToNewFormat } from '../../../modules/date/utils/DateHelpers';
import { DateFormatUsingDateFns } from '../../../modules/date/DateFormat.const';
import { Icon } from '../../../components/icons/Icon';
import { Tag } from '../../../components/icons/IconList';
import { CalendarDay } from '../../../components/icons/IconList';
import { PersonRaisedHand } from '../../../components/icons/IconList';
import { CommentFill } from '../../../components/icons/IconList';
import { Award } from '../../../components/icons/IconList';

interface Props {
	recognition: Recognition;
	modalId?: string;
	onClose?: () => void;
}

export function RecognitionViewModal({
	recognition,
	modalId = 'recognition_view_modal',
	onClose,
}: Props) {
	const dispatch = useDispatch();
	const handleCloseModal = () => {
		if (onClose) {
			onClose();
		}
		dispatch(recognitionModalSlice.actions.hide());
	};

	const recognitionDateElement = (
		<SkePopover
			closeOnContentClick
			className="tooltop-auto-width"
			placement="top-end"
			trigger={['hover']}
			triggerElement={<div>{recognition?.date}</div>}
		>
			<div className="fw-bold d-flex gap-2 text-muted">
				<div className="d-flex align-items-center gap-1">
					<Icon
						classes="text-muted"
						icon={CalendarDay}
						type="svg"
						size="xs" />
					<label
						className="fw-muted fs-8"
						htmlFor="created-at">
						Created At:
					</label>
				</div>
				<div
					className="fs-6 text-black fw-normal"
					id="created-at">
					{parseDateObjectToNewFormat(
						new Date(recognition?.created_at || ''),
						DateFormatUsingDateFns.PerfectDate,
					)}
				</div>
			</div>
		</SkePopover>
	);

	const items = getInfoGridProps();

	function getInfoGridProps() {
		return [
			{
				label: 'Category',
				icon: <Icon
					classes="text-muted"
					icon={Tag}
					type="svg"
					size="xs" />,
				value: recognition.category?.name,
				columnSpan: 2,
			},
			{
				label: 'Date',
				icon: <Icon
					classes="text-muted"
					icon={CalendarDay}
					type="svg"
					size="xs" />,
				value: recognitionDateElement,
				columnSpan: 2,
			},
			{
				label: 'Recognized By',
				icon: <Icon
					classes="text-muted"
					icon={PersonRaisedHand}
					type="svg"
					size="xs" />,
				value: `${recognition?.creator?.firstName} ${recognition?.creator?.lastName}`,
				columnSpan: 2,
			},
			{
				icon: <Icon
					classes="text-muted"
					icon={CommentFill}
					type="svg"
					size="xs" />,
				label: 'Message',
				value: recognition.message || 'No message sent',
				columnSpan: 2,
			},
			...(recognition.translatedMessage &&
			recognition.sourceLanguage &&
			recognition.translatedLanguage
				? [
					{
						icon: <i className="fas fa-language fs-6"></i>,
						label: `Translated from ${getNameFromLanguageAbbr(
							recognition.sourceLanguage,
						)} to ${getNameFromLanguageAbbr(recognition.translatedLanguage)}`,
						value: recognition.translatedMessage,
						columnSpan: 2,
					},
				]
				: []),
		];
	}

	return (
		<SkeModal
			show={true}
			title="View Recognition"
			fullscreen="sm-down"
			onClose={handleCloseModal}
			customHeaderContents={
				<div>
					<div className="d-flex align-items-center gap-2 mb-3">
						<Icon
							classes="text-black"
							icon={Award}
							type="svg"
							size="md" />
						<h1 className="m-0">Recognition</h1>
					</div>
					<div style={{maxWidth: '300px'}}>
						<EmployeeBadge
							size={AvatarSize.md}
							employee={recognition?.employee}
							team={recognition?.team}
						/>
					</div>
				</div>
			}
			footerContents={
				<div className="button-container p-10 d-flex justify-content-end">
					<Button
						className="px-10"
						variant="primary"
						onClick={() => {
							dispatch(
								recognitionModalSlice.actions.show({
									mode: ModalDisplayMode.Print,
									record: recognition,
								}),
							);
						}}
					>
						Print PDF
					</Button>
				</div>
			}
		>
			<SkeInfoGrid items={items}></SkeInfoGrid>
		</SkeModal>
	);
}
