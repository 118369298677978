import clsx from 'clsx';

interface SkeSpinnerProps {
	label?: string;
	labelSize?: 'fs-1' | 'fs-2' | 'fs-3' | 'fs-4' | 'fs-5' | 'fs-6'
	className?: string;
	overlay?: boolean;
	spinnerSize?: 'sm' | 'md' | 'lg';
}

export function SkeSpinner({
														 label = 'Getting Data...',
														 labelSize = 'fs-1',
														 className,
														 overlay = true,
														 spinnerSize = 'sm',
													 }: SkeSpinnerProps) {

	return (
		<div
			className={clsx('indicator-progress poppins_medium d-block',
				className,
				{
					'opacity-50': overlay,
					'top-0': overlay,
					'end-0': overlay,
					'h-100': overlay,
					'w-100': overlay,
			})}
			style={{
				background: overlay ? 'lightblue' : 'inherit',
			}}
		>
			{/* overlay requires the parent have `position: relative` so it'll be centered in visible area */}
			<div className='position-sticky top-50 start-50 d-flex align-items-center justify-content-center'>
				<span className={clsx('text-dark cursor-default', labelSize)}>
					{label}
				</span>
				<span className={`spinner-border spinner-border-${spinnerSize} align-middle ms-2`}></span>
			</div>
		</div>
	);
}
