import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Emoji } from 'emoji-mart';

interface EmojiPickerProps {
	onEmojiSelect: (emoji: Emoji) => void;
	onOutsideClick?: () => void;
	exceptEmojis?: string[];
	theme?: string
}

export const SkeEmojiPicker = ({
															onEmojiSelect,
															onOutsideClick,
															exceptEmojis = ['peach', 'eggplant', 'gun'],
															theme = 'light'
														}: EmojiPickerProps) => {

	const handleEmojiSelect = (emoji: any) => {
		console.log('Selected Emoji:', emoji.native);
		onEmojiSelect(emoji);
	};

	return (
		<Picker
			onClickOutside={onOutsideClick}
			theme={theme}
			data={data}
			onEmojiSelect={handleEmojiSelect}
			exceptEmojis={exceptEmojis} />
	);
};
