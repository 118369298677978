import { isUndefined } from 'lodash';
import { isValidElement, JSX } from 'react';

export type InfoItem = {
	icon?: JSX.Element;
	label: string;
	value: string | number | boolean | undefined | JSX.Element;
	columnSpan?: number;
}

export type InfoGridProps = {
	items: InfoItem[];
	columns?: 1 | 2;
}

export function SkeInfoGrid({
	items,
	columns = 2,
}: InfoGridProps) {
	function getItemValue(value: any) {
		if (isUndefined(value) || value === '') {
			return 'No Data';
		}

		return isValidElement(value) ? value : `${value}`;
	}

	return (
		<div
			className="border border-light bg-light p-3 rounded d-grid gap-3"
			style={{
				gridTemplateColumns: '1fr 1fr',
				gridAutoRows: 'minmax(50px, auto)',
			}}
		>
			{items.map((item, index) => (
				<div
					key={index}
					style={{gridColumn: `span ${item.columnSpan === 1 ? '1' : '2'}`}}>
					<div className="d-flex align-items-center p-2 border rounded bg-white h-100">
						<div className="d-flex flex-column h-100 text-muted">
							<div className="label-wrapper d-flex align-items-center gap-1">
								{item.icon && item.icon}
								<div className="fw-bold fs-8">{item.label}</div>
							</div>
							<div className="text-black fs-6">
								{getItemValue(item.value)}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}
