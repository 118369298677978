import BoxArrowUpRight from '../../../images/icons/box-arrow-up-right.svg';
import x from '../../../images/connections/x.svg';
import funnelFill from '../../../images/icons/funnel-fill.svg';
import download from '../../../images/icons/download.svg';
import geoAlt from '../../../images/icons/geo-alt.svg';
import people from '../../../images/icons/people.svg';
import folderClosed from '../../../images/icons/folder2.svg';
import folderOpened from '../../../images/icons/folder2-open.svg';
import infoCircle from '../../../images/icons/info-circle.svg';
import translate from '../../../images/icons/translate.svg';
import caretDown from '../../../images/icons/caret-down.svg';
import caretRight from '../../../images/icons/caret-right.svg';
import caretUp from '../../../images/icons/caret-up.svg';
import caretLeft from '../../../images/icons/caret-left.svg';
import clock from '../../../images/icons/clock.svg';
import personBadge from '../../../images/icons/person-badge.svg';
import trash3 from '../../../images/icons/trash3.svg';
import pencil from '../../../images/icons/pencil.svg';
import cake from '../../../images/icons/cake.svg';
import anniversary from '../../../images/icons/bootstrap/envelope-open-heart.svg';
import exclamationTriangle from '../../../images/icons/bootstrap/exclamation-triangle.svg';
import exclamationTriangleFill from '../../../images/icons/bootstrap/exclamation-triangle-fill.svg';
import warning from '../../../images/icons/exclamation-triangle.svg';
import bell from '../../../images/icons/bell.svg';
import bellFill from '../../../images/icons/bell-fill.svg';
import tools from '../../../images/icons/tools.svg';
import printer from '../../../images/icons/printer.svg';
import printerFill from '../../../images/icons/printer-fill.svg';
import refresh from '../../../images/icons/arrow-clockwise.svg';
import search from '../../../images/icons/search.svg';
import binoculars from '../../../images/icons/binoculars.svg';
import binocularsFill from '../../../images/icons/binoculars-fill.svg';
import floppy from '../../../images/icons/floppy.svg';
import floppyFill from '../../../images/icons/floppy-fill.svg';
import building from '../../../images/icons/bootstrap/building.svg';
import buildingFill from '../../../images/icons/bootstrap/building-fill.svg';
import openai from '../../../images/icons/openai.svg';
import clipboardPlus from '../../../images/icons/clipboard-plus.svg';
import sendFill from '../../../images/icons/bootstrap/send-fill.svg';
import send from '../../../images/icons/bootstrap/send.svg';
import personAdd from '../../../images/icons/bootstrap/person-add.svg';
import personLinesFill from '../../../images/icons/bootstrap/person-lines-fill.svg';
import checkSquareFill from '../../../images/icons/bootstrap/check-square-fill.svg';
import exclamationSquareFill from '../../../images/icons/bootstrap/exclamation-square-fill.svg';
import gearWideConnected from '../../../images/icons/bootstrap/gear-wide-connected.svg';
import random from '../../../images/icons/font-awesome/random.svg';
import calendarDay from '../../../images/icons/font-awesome/calendar-day.svg';
import commentDots from '../../../images/icons/font-awesome/comment-dots.svg';
import commentDotsFill from '../../../images/icons/font-awesome/comment-dots-fill.svg';
import calculator from '../../../images/icons/font-awesome/calculator.svg';
import hourGlass from '../../../images/icons/font-awesome/hour-glass.svg';
import userCheck from '../../../images/icons/font-awesome/user-check.svg';
import userPlus from '../../../images/icons/font-awesome/user-plus.svg';
import file from '../../../images/icons/font-awesome/file.svg';
import fileFill from '../../../images/icons/font-awesome/file-fill.svg';
import tag from '../../../images/icons/font-awesome/tag.svg';
import usersTwo from '../../../images/icons/font-awesome/users-two.svg';
import comments from '../../../images/icons/font-awesome/comments.svg';
import commentsFill from '../../../images/icons/font-awesome/comments-fill.svg';
import personRaisedHand from '../../../images/icons/bootstrap/person-raised-hand.svg';
import commentFill from '../../../images/icons/font-awesome/comment-fill.svg';
import comment from '../../../images/icons/font-awesome/comment.svg';
import award from '../../../images/icons/font-awesome/award.svg'
import chalkboardTeacher from '../../../images/icons/font-awesome/chalkboard-teacher.svg'
import calendarX from '../../../images/icons/font-awesome/calendar-x.svg'
import personExclamation from '../../../images/icons/bootstrap/person-exclamation.svg'
import personExclamationFill from '../../../images/icons/bootstrap/person-exclamation-fill.svg'

export interface IconBase {
	iconType: 'svg' | 'font';
}

export interface SvgIcon extends IconBase {
	src: string;
	iconType: 'svg';
	element: 'img' | 'svg';
}

export const ArrowUpRightBoxIcon: SvgIcon = {
	src: BoxArrowUpRight,
	iconType: 'svg',
	element: 'svg',
};

export const XIcon: SvgIcon = {
	src: x,
	iconType: 'svg',
	element: 'svg',
};

export const Filter: SvgIcon = {
	src: funnelFill,
	iconType: 'svg',
	element: 'svg',
};

export const Download: SvgIcon = {
	src: download,
	iconType: 'svg',
	element: 'svg',
};

export const Geo: SvgIcon = {
	src: geoAlt,
	iconType: 'svg',
	element: 'svg',
};

export const Cake: SvgIcon = {
	src: cake,
	iconType: 'svg',
	element: 'svg',
};

export const Anniversary: SvgIcon = {
	src: anniversary,
	iconType: 'svg',
	element: 'svg',
};

export const ExclamationTriangle: SvgIcon = {
	src: exclamationTriangle,
	iconType: 'svg',
	element: 'svg',
};

export const ExclamationTriangleFill: SvgIcon = {
	src: exclamationTriangleFill,
	iconType: 'svg',
	element: 'svg',
};


export const People: SvgIcon = {
	src: people,
	iconType: 'svg',
	element: 'svg',
};

export const FolderClosed: SvgIcon = {
	src: folderClosed,
	iconType: 'svg',
	element: 'svg',
};

export const FolderOpened: SvgIcon = {
	src: folderOpened,
	iconType: 'svg',
	element: 'svg',
};

export const Clock: SvgIcon = {
	src: clock,
	iconType: 'svg',
	element: 'svg',
};

export const InfoCircle: SvgIcon = {
	src: infoCircle,
	iconType: 'svg',
	element: 'svg',
};

export const Translate: SvgIcon = {
	src: translate,
	iconType: 'svg',
	element: 'svg',
};

export const CaretDown: SvgIcon = {
	src: caretDown,
	iconType: 'svg',
	element: 'svg',
};

export const CaretRight: SvgIcon = {
	src: caretRight,
	iconType: 'svg',
	element: 'svg',
};

export const CaretUp: SvgIcon = {
	src: caretUp,
	iconType: 'svg',
	element: 'svg',
};

export const CaretLeft: SvgIcon = {
	src: caretLeft,
	iconType: 'svg',
	element: 'svg',
};

export const PersonBadge: SvgIcon = {
	src: personBadge,
	iconType: 'svg',
	element: 'svg',
};

export const Trash: SvgIcon = {
	src: trash3,
	iconType: 'svg',
	element: 'svg',
};

export const Pencil: SvgIcon = {
	src: pencil,
	iconType: 'svg',
	element: 'svg',
};

export const Warning: SvgIcon = {
	src: warning,
	iconType: 'svg',
	element: 'svg',
};

export const Bell: SvgIcon = {
	src: bell,
	iconType: 'svg',
	element: 'svg',
};

export const BellFill: SvgIcon = {
	src: bellFill,
	iconType: 'svg',
	element: 'svg',
};

export const Tools: SvgIcon = {
	src: tools,
	iconType: 'svg',
	element: 'svg',
};

export const Printer: SvgIcon = {
	src: printer,
	iconType: 'svg',
	element: 'svg',
};

export const PrinterFill: SvgIcon = {
	src: printerFill,
	iconType: 'svg',
	element: 'svg',
};

export const Refresh: SvgIcon = {
	src: refresh,
	iconType: 'svg',
	element: 'svg',
};

export const Search: SvgIcon = {
	src: search,
	iconType: 'svg',
	element: 'svg',
};

export const Binoculars: SvgIcon = {
	src: binoculars,
	iconType: 'svg',
	element: 'svg',
};

export const BinocularsFill: SvgIcon = {
	src: binocularsFill,
	iconType: 'svg',
	element: 'svg',
};

export const Save: SvgIcon = {
	src: floppy,
	iconType: 'svg',
	element: 'svg',
};

export const SaveFill: SvgIcon = {
	src: floppyFill,
	iconType: 'svg',
	element: 'svg',
};

export const Building: SvgIcon = {
	src: building,
	iconType: 'svg',
	element: 'svg',
};

export const BuildingFill: SvgIcon = {
	src: buildingFill,
	iconType: 'svg',
	element: 'svg',
};

export const OpenAI: SvgIcon = {
	src: openai,
	iconType: 'svg',
	element: 'svg',
};


export const ClipboardPlus: SvgIcon = {
	src: clipboardPlus,
	iconType: 'svg',
	element: 'svg',
};

export const SendFill: SvgIcon = {
	src: sendFill,
	iconType: 'svg',
	element: 'svg',
};

export const Send: SvgIcon = {
	src: send,
	iconType: 'svg',
	element: 'svg',
};

export const PersonAdd: SvgIcon = {
	src: personAdd,
	iconType: 'svg',
	element: 'svg',
};

export const PersonLinesFill: SvgIcon = {
	src: personLinesFill,
	iconType: 'svg',
	element: 'svg',
};

export const ExclamationSquareFill: SvgIcon = {
	src: exclamationSquareFill,
	iconType: 'svg',
	element: 'svg',
};

export const CheckSquareFill: SvgIcon = {
	src: checkSquareFill,
	iconType: 'svg',
	element: 'svg',
};

export const GearWideConnected: SvgIcon = {
	src: gearWideConnected,
	iconType: 'svg',
	element: 'svg',
};

export const Random: SvgIcon = {
	src: random,
	iconType: 'svg',
	element: 'svg',
};

export const CalendarDay: SvgIcon = {
	src: calendarDay,
	iconType: 'svg',
	element: 'svg',
};

export const CommentDots: SvgIcon = {
	src: commentDots,
	iconType: 'svg',
	element: 'svg',
};

export const CommentDotsFill: SvgIcon = {
	src: commentDotsFill,
	iconType: 'svg',
	element: 'svg',
};

export const Calculator: SvgIcon = {
	src: calculator,
	iconType: 'svg',
	element: 'svg',
};

export const HourGlass: SvgIcon = {
	src: hourGlass,
	iconType: 'svg',
	element: 'svg',
};

export const UserCheck: SvgIcon = {
	src: userCheck,
	iconType: 'svg',
	element: 'svg',
};

export const UserPlus: SvgIcon = {
	src: userPlus,
	iconType: 'svg',
	element: 'svg',
};

export const File: SvgIcon = {
	src: file,
	iconType: 'svg',
	element: 'svg',
};

export const FileFill: SvgIcon = {
	src: fileFill,
	iconType: 'svg',
	element: 'svg',
};

export const Tag: SvgIcon = {
	src: tag,
	iconType: 'svg',
	element: 'svg',
};

export const UsersTwo: SvgIcon = {
	src: usersTwo,
	iconType: 'svg',
	element: 'svg',
};

export const Comments: SvgIcon = {
	src: comments,
	iconType: 'svg',
	element: 'svg',
};

export const CommentsFill: SvgIcon = {
	src: commentsFill,
	iconType: 'svg',
	element: 'svg',
};

export const PersonRaisedHand: SvgIcon = {
	src: personRaisedHand,
	iconType: 'svg',
	element: 'svg',
};

export const CommentFill: SvgIcon = {
	src: commentFill,
	iconType: 'svg',
	element: 'svg',
};

export const Comment: SvgIcon = {
	src: comment,
	iconType: 'svg',
	element: 'svg',
};

export const Award: SvgIcon = {
	src: award,
	iconType: 'svg',
	element: 'svg',
};

export const ChalkboardTeacher: SvgIcon = {
	src: chalkboardTeacher,
	iconType: 'svg',
	element: 'svg',
};

export const CalendarX: SvgIcon = {
	src: calendarX,
	iconType: 'svg',
	element: 'svg',
};

export const PersonExclamation: SvgIcon = {
	src: personExclamation,
	iconType: 'svg',
	element: 'svg',
};

export const PersonExclamationFill: SvgIcon = {
	src: personExclamationFill,
	iconType: 'svg',
	element: 'svg',
};
