import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AttendanceModal } from '../../../../app/pages/connections/attendance/AttendanceModal';
import { AttendanceViewModal } from '../../../../app/pages/connections/attendance/AttendanceViewModal';
import { AttendanceType } from '../../../../app/types/AttendanceType';
import { EmployeeType } from '../../../../app/types/EmployeeType';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { attendanceModalSlice, AttendanceModalState, AttendanceModalStateUnion } from '../../attendance-modal.slice';
import { attendanceSlice } from '../../attendance.api';

interface Props {
	state: AttendanceModalStateUnion & Pick<AttendanceModalState, 'showModal' | 'recordId'>;
}

export function AttendanceLayout({state}: Props) {
	const dispatch = useDispatch();
	const [deleteAttendance] = attendanceSlice.useDeleteAttendanceMutation();
	const attendanceEditModalRef = useRef<HTMLButtonElement>(null);
	const previousState = useRef<AttendanceModalStateUnion>();
	const [getOneAttendanceFilter, setGetOneAttendanceFilter] = useState<{id: number}>();
	const {
		data: attendance,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = attendanceSlice.useGetOneAttendanceQuery(getOneAttendanceFilter || skipToken);

	useEffect(() => {
		if (!state.record && state.recordId) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Update:
					attendanceEditModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!!attendance && attendance.id === state.recordId && !state.record) {
			dispatch(attendanceModalSlice.actions.show({
				...state,
				record: attendance,
			}));
		}
	}, [attendance, fulfilledTimeStamp]);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneAttendanceFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};

	const handleCancelDelete = () => {
		dispatch(attendanceModalSlice.actions.hide());
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (state.mode !== ModalDisplayMode.Delete || !state.recordId) {
			throw new Error('Unable to delete record as no ID was passed in');
		}
		deleteAttendance({id: state.recordId})
			.unwrap()
			.then(() => {
				successToast();
				dispatch(attendanceModalSlice.actions.hide());
			})
			.catch(() => {
				errorToast();
				dispatch(attendanceModalSlice.actions.hide());
			});
	};

	return (
		<>
			<button
				className="d-none"
				ref={attendanceEditModalRef}
				data-bs-toggle="modal"
				data-bs-target="#connections-attendance-edit">
			</button>
			{state.record && state.mode === ModalDisplayMode.Read &&
				<AttendanceViewModal
					attendance={(state.record as unknown as AttendanceType)}
					modalId="connections-attendance-view"
					onClose={() => dispatch(attendanceModalSlice.actions.hide())}
				/>
			}
			{state.record && state.mode === ModalDisplayMode.Update &&
				<AttendanceModal
					employee={(state.record.employee as unknown as EmployeeType)}
					attendance={(state.record as unknown as AttendanceType)}
					modalId="connections-attendance-edit"
					mode="edit"
					refresh={() => dispatch(attendanceModalSlice.actions.hide())}
					onClose={() => dispatch(attendanceModalSlice.actions.hide())}
				/>
			}
			{state.mode === ModalDisplayMode.Delete &&
				<SkeDialogDelete
					onCancel={handleCancelDelete}
					onConfirm={handleConfirmDelete}
					successMessage="Attendance deleted"
					message="Are you sure you want to delete this?"
				/>
			}
		</>
	);
}
