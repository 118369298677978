import React, { useEffect, useState } from "react";
import {
  Chart,
  ChartOptions,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";

Chart.register(
  BarElement,
  Title,
  Tooltip,
  Legend
);

export interface LineChartDatum {
  label: string;
  data: any[];
  backgroundColor?: string;
}

interface Props {
  labels: string[];
  data: LineChartDatum[];
  options: ChartOptions;
  isVisible: boolean;
}

export function LineChart({
                                   labels,
                                   data,
                                   options,
                                   isVisible
                                 }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const [formattedData, setFormattedData] = useState<any>({});

  useEffect(() => {
    setFormattedData({
      labels,
      datasets: data
    });
    // console.log("got data for the line chart", formattedData, labels, data);
    setShow(true);
  }, [data, labels, options]);

  return (
    <>
      {show && isVisible && (
        <div
          style={{
            height: "550px",
            resize: "vertical",
            overflow: "hidden",
            border: "1px solid #ddd",
            padding: "3rem",
          }}
        >
          <Line
            options={options}
            data={formattedData} />
        </div>
      )}
    </>
  );
}
