import { SetOptional } from 'type-fest';
import { Employee } from '../../../app/modules/employee/models/Employee.model';
import { User } from '../../../app/modules/user/models/User.model';
import { CategoryModel } from '../../category/interfaces/category.model';

export interface GetMassCommunicationApiRequestModel {
	category?: string;
}

export enum CommunicationTagTypes {
	Communication = 'COMMUNICATION',
	CommunicationCategories = 'COMMUNICATION_CATEGORIES',
}

export type SkeCommunicationRecipientType = 'EMPLOYEE' | 'USER' | 'PERSON';

// from API
export enum SkeCommunicationMethod {
	Sms = 'SMS',
	Email = 'EMAIL',
	InApp = 'IN_APP',
}

export interface CommunicationRecipient {
	id: number;
	type: SkeCommunicationRecipientType;
}

export interface CommunicationCreateModel {
	category_id: number;
	content: string;
	recipients: CommunicationRecipient[];
}

export interface CommunicationCreateResponse {
	comm: {
		comm_header_id: number;
	};
}

export interface CommunicationGetOneResponseResults {
	comm: CommunicationHeader;
}

export interface CommunicationPrecheckResponse {
	users: User[];
	categories: CategoryModel[];
}

// from API
export enum SkeCommunicationStatus {
	NewRequest = 'NEW_REQUEST',
	Scheduled = 'SCHEDULED',
	Processing = 'PROCESSING',
	Succeeded = 'SUCCEEDED',
	Errored = 'ERRORED',
	Cancelled = 'CANCELLED',
	Completed = 'COMPLETED',
	Retry = 'RETRYING',
	Delivered = 'DELIVERED',
	Sent = 'SENT',
}

export type CommunicationHeader = {
	id: number;
	tenant_id: string;
	tenant_id_new: null;
	user_id: number;
	creator_id: null;
	category_id: number;
	type: SkeCommunicationMethod[];
	status: SkeCommunicationStatus;
	category: CategoryModel;
	context: {
		[value in SkeCommunicationMethod]: {
			type: SkeCommunicationMethod;
			message: string;
		};
	};
	send_at: string;
	created_at: string;
	updated_at: string;
	pathways: CommunicationPathway[];
};

export interface CommunicationPathway {
	id: number;
	tenant_id: number;
	tenant_id_new: null;
	employee_id: number | null;
	user_id: number | null;
	person_id: null;
	// not yet wired in on API
	vendor_id: null;
	category_id: number;
	type: SkeCommunicationMethod;
	target: string;
	status: Exclude<SkeCommunicationStatus, SkeCommunicationStatus.Completed>;
	context: null;
	metadata: null;
	confirmed_at: string | null;
	send_at: string;
	created_at: string;
	vendor_pk_id: string;
	header_id: number;
	updated_at: string;
	tags: string[] | null;
	// TODO: for MVP, left it this way, even for users. Will be able to consolidate with Person migration
	employee: Pick<Employee,
		| 'id'
		| 'employeeId'
		| 'firstName'
		| 'lastName'
		| 'phone'
	> & {
		type: SkeCommunicationRecipientType
	};
	// TODO: this is getting too much info, is also returned
	user: User;
}

export interface CommunicationFetchModel {
	comm: CommunicationHeader;
}

export interface CommunicationCategoryModel extends Pick<CategoryModel, 'id' | 'name'> {}

export interface CreateCommunicationApiRequestModel
	extends Omit<CommunicationCreateModel, 'id'> {}

export interface CommunicationRetryRequestModel {
	id: number;
}

export interface CommunicationRetryResponse extends SetOptional<CommunicationGetOneResponseResults, 'comm'> {}
