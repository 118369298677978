import { skipToken } from '@reduxjs/toolkit/query';
import {useEffect, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';
import { RecognitionModal } from '../../../../app/pages/connections/recognition/RecognitionModal';
import { RecognitionViewModal } from '../../../../app/pages/connections/recognition/RecognitionViewModal';
import {Font, Image, Page, StyleSheet, Text, View} from '@react-pdf/renderer';
import { SkeDialogDelete } from '../../../../common/components/dialog-delete';
import { ModalDisplayMode } from '../../../../common/interfaces/modal.model';
import { recognitionSlice } from '../../recognition.api';
import { recognitionModalSlice, RecognitionModalState, RecognitionModalStateUnion } from '../../recognition-modal.slice';
import {Recognition} from '../../interfaces/recognition.model';
import {SkePdf} from '../../../../common/pdf/SkePdf';

interface Props {
	state: RecognitionModalStateUnion & Pick<RecognitionModalState, 'showModal' | 'recordId'>;
}

export function RecognitionLayout({ state }: Props) {
	const dispatch = useDispatch();
	const [deleteRecognition] = recognitionSlice.useDeleteRecognitionMutation();
	const downloadButtonRef = useRef<HTMLButtonElement>(null);
	const recognitionViewModalRef = useRef<HTMLButtonElement>(null);
	const recognitionEditModalRef = useRef<HTMLButtonElement>(null);
	const previousState = useRef<RecognitionModalStateUnion>();
	const [getOneRecognitionFilter, setGetOneRecognitionFilter] = useState<{id: number}>();
	const {
		data: recognition,
		refetch,
		isUninitialized,
		fulfilledTimeStamp,
	} = recognitionSlice.useGetOneRecognitionQuery( getOneRecognitionFilter || skipToken)
	const [showPdfModal, setShowPdfModal] = useState<boolean>(true);

	useEffect(() => {
		if(state.mode === ModalDisplayMode.Print && state.record){
			setGetOneRecognitionFilter({id: state.record.id})
			setShowPdfModal(true);
		}
		if (!state.record && state.recordId) {
			const force = !state.record && !!previousState.current?.record;
			handleLoadRecord(state.recordId, force);
			return;
		}
		if (state.record && state.record?.id === state.recordId && state.showModal) {
			switch (state.mode) {
				case ModalDisplayMode.Read:
					recognitionViewModalRef.current?.click();
					break;
				case ModalDisplayMode.Update:
					recognitionEditModalRef.current?.click();
					break;
			}
			previousState.current = state;
			return;
		}
	}, [state]);

	useEffect(() => {
		if (!!recognition && recognition.id === state.recordId) {
			dispatch(recognitionModalSlice.actions.show({
				...state,
				record: recognition,
			}));
		}
	}, [recognition, fulfilledTimeStamp]);

	const handleLoadRecord = (id: number, force: boolean) => {
		setGetOneRecognitionFilter({id});
		if (force) {
			if (isUninitialized) {
				return;
			}
			refetch();
		}
	};

	const handleCancelDelete = () => {
		dispatch(recognitionModalSlice.actions.hide());
	};

	const handleConfirmDelete = (errorToast: Function, successToast: Function) => {
		if (state.mode !== ModalDisplayMode.Delete || !state.recordId) {
			throw new Error('Unable to delete record as no ID was passed in');
		}
		deleteRecognition({ id: state.recordId})
		.unwrap()
		.then(() => {
			successToast();
			dispatch(recognitionModalSlice.actions.hide());
		})
		.catch(() => {
			errorToast();
			dispatch(recognitionModalSlice.actions.hide());
		});
	};

	Font.register({
		family: 'PinyonScript-Regular',
		src: '/static/media/PinyonScript-Regular.8d81e07e5bc82ec02bf7.ttf',
	});

	const goldColor = '#a67c00'

	const styles = StyleSheet.create({
		page: {
			flexDirection: 'column',
			backgroundColor: '#FFFFFF',
			alignItems: 'center',
			padding: 40,
			fontFamily: 'Times-Italic',
		},
		certificateContainer: {
			borderWidth: 3,
			borderColor: goldColor,
			padding: 24,
			width: '100%',
			height: 'auto',
			fontFamily: 'Times-Italic',
		},
		title: {
			fontSize: 40,
			color: goldColor,
			textAlign: 'center',
			width: '100%',
			marginBottom: 12,
			justifyContent: 'center',
			flexDirection: 'row',
			fontFamily: 'PinyonScript-Regular',
		},
		awardText: {
			fontSize: 20,
			textAlign: 'center',
			marginBottom: 16,
			fontFamily: 'Times-Roman',
			fontWeight: 'extralight',
		},
		recipientName: {
			fontSize: 36,
			textAlign: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
			color: goldColor,
			margin: 2,
			fontFamily: 'Times-Bold',
		},
		recognitionText: {
			fontSize: 20,
			textAlign: 'center',
			margin: 16,
			fontFamily: 'Times-Italic',
		},
		signature: {
			fontSize: 16,
			textAlign: 'center',
			marginTop: 8,
			color: goldColor,
			fontFamily: 'Times-Italic',
		},
		signatureLine: {
			borderBottomWidth: 1,
			borderTopColor: goldColor,
			width: '50%',
			marginTop: 4,
			alignSelf: 'center',
		},
		footer: {
			fontSize: 12,
			textAlign: 'left',
			marginTop: 24,
			fontFamily: 'Helvetica',
			textTransform: 'uppercase',
			textDecoration: 'underline',
		},
		logo: {
			width: 'auto',
			height: 32,
			position: 'absolute',
			bottom: 12,
			left: 12,
		},

	});

	const removeSignatureFromMessage = (message: string, creator: Recognition['creator']): string => {
		const initials = `${creator.firstName.charAt(0)}${creator.lastName.charAt((0))}`;
		const fullName = `${creator.firstName} ${creator.lastName}`;
		const firstName = `${creator.firstName}`;
		const signatureRegexpPattern = `\\s*\\-(\\b${fullName}\\b|${initials}|${firstName})\\s*$`;
		const regexp = new RegExp(signatureRegexpPattern, 'gi');
		return message.replace(regexp, '');
	}

	const handleClose = ()=>{
		if(showPdfModal){
			setShowPdfModal(false)
		}
	}

	return (
		<>
			<button
				className="d-none"
				ref={recognitionViewModalRef}
				// data-bs-toggle="modal" -- React Modal does not like this tag
				data-bs-target="#connections-recognition-view">
			</button>
			{state.record && state.mode === ModalDisplayMode.Read &&
				<RecognitionViewModal
					recognition={state.record}
					modalId="connections-recognition-view"
					onClose={() => dispatch(recognitionModalSlice.actions.hide())}
				/>
			}
			{state.showModal && (
				<>
					{state.mode === ModalDisplayMode.Create && (
						<RecognitionModal
							mode='new'
							employee={state.employee} />
					)}
					{state.mode === ModalDisplayMode.Read && state.record && (
						<RecognitionViewModal
							recognition={state.record}
						/>
					)}
					{state.mode === ModalDisplayMode.Update && state.record && (
						<RecognitionModal
							mode='edit'
							recognition={state.record}
							employee={state.record.employee} />
					)}
					{state.mode === ModalDisplayMode.Delete && (
						<SkeDialogDelete
							onCancel={handleCancelDelete}
							onConfirm={handleConfirmDelete}
							successMessage='Recognition deleted'
							message='Are you sure you want to delete this?'
						/>
					)}
					{/*intentionally not hiding when not in print mode due to race condition*/}
					{state.mode === ModalDisplayMode.Print && state.record && styles && (
						<SkePdf
							show={showPdfModal}
							onClose={handleClose}
							title='Print Certificate'
							height={500}
							width={400}
						>
							<Page size="A4" orientation={'landscape'}
										style={styles.page}>
								<View style={styles.certificateContainer}>
									<Image style={styles.logo}
												 src="public/media/logos/simple_logo.png" />
									<Text style={[styles.title]}>Certificate of Recognition</Text>
									<Text style={styles.awardText}>This award is given to</Text>
									<Text style={[
										styles.recipientName,
									]}>{recognition?.employee.firstName} {recognition?.employee.lastName}</Text>
									<Text style={styles.awardText}>For</Text>
									<Text style={styles.recognitionText}>{removeSignatureFromMessage(state.record.translatedMessage || state.record.message, state.record.creator)}</Text>
									<View style={styles.signatureLine} />
									<Text style={[
										styles.signature,
									]}>{recognition?.creator?.firstName} {recognition?.creator?.lastName} on {recognition?.date}</Text>
									<Image
										style={styles.logo}
										src='/static/media/secchi_light_logo.5063e4dbcd79cab411b4.png' />
								</View>
							</Page>
						</SkePdf>
					)}
				</>
			)}
		</>
	);
}
