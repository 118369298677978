import { Icon } from '../../../../../app/components/icons/Icon';
import { CalendarDay, CommentsFill, Tag, UsersTwo } from '../../../../../app/components/icons/IconList';
import { DateFormatUsingDateFns } from '../../../../../app/modules/date/DateFormat.const';
import { parseDateObjectToNewFormat } from '../../../../../app/modules/date/utils/DateHelpers';
import { SkeInfoGrid } from '../../../../../common/components/InfoGrid';
import { SkePopover } from '../../../../../common/components/Popover';
import { Coaching } from '../../../interfaces/coaching.model';

interface CoachingViewProps {
	coaching?: Coaching;
}

export function CoachingView({coaching}: CoachingViewProps) {
	if (!coaching) {
		return <p className="my-20 text-center fs-4">No Coaching Record Provided</p>;
	}

	const coachingDateElement = (
		<SkePopover
			closeOnContentClick
			className="tooltop-auto-width"
			placement="top-end"
			trigger={['hover']}
			triggerElement={<div>{coaching?.date}</div>}
		>
			<div className="fw-bold d-flex gap-2 text-muted">
				<div className="d-flex align-items-center gap-1">
					<Icon
						classes="text-muted"
						icon={CalendarDay}
						type="svg"
						size="xs" />
					<label
						className="fw-muted fs-8"
						htmlFor="created-at">
						Created At:
					</label>
				</div>
				<div
					className="fs-6 text-black fw-normal"
					id="created-at">
					{parseDateObjectToNewFormat(new Date(coaching?.created_at || ''), DateFormatUsingDateFns.PerfectDate)}
				</div>
			</div>
		</SkePopover>
	);

	const items = getInfoGridProps();

	function getInfoGridProps() {
		return [
			{
				label: 'Category',
				icon: <Icon
					classes="text-muted"
					icon={Tag}
					type="svg"
					size="xs" />,
				value: coaching?.category.name,
				columnSpan: 1,
			},
			{
				label: 'Event Date',
				icon: <Icon
					classes="text-muted"
					icon={CalendarDay}
					type="svg"
					size="xs" />,
				value: coachingDateElement,
				columnSpan: 1,
			},
			{
				label: 'Coached By',
				icon: <Icon
					classes="text-muted"
					icon={UsersTwo}
					type="svg"
					size="xs" />,
				value: `${coaching?.coacher?.firstName} ${coaching?.coacher?.lastName}`,
				columnSpan: 2,
			},
			{
				label: 'Comments',
				icon: <Icon
					classes="text-muted"
					icon={CommentsFill}
					type="svg"
					size="xs" />,
				value: coaching?.comment || 'No Details Provided',
				columnSpan: 2,
			},
		];
	}

	return <SkeInfoGrid items={items}></SkeInfoGrid>;
}
