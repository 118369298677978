import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { AvatarSize } from '../../../../app/components/Avatar';
import { DatePicker } from '../../../../app/components/DatePicker';
import { EmployeeBadge } from '../../../../app/components/EmployeeBadge';
import { createCoaching, updateCoaching } from '../../../../app/CRUD/CoachingCRUD';
import { DateFormatUsingDateFns } from '../../../../app/modules/date/DateFormat.const';
import { parseDateObjectToNewFormat, parseYmdDatesToDateObject } from '../../../../app/modules/date/utils/DateHelpers';
import { Employee } from '../../../../app/modules/employee/models/Employee.model';
import { CoachingType } from '../../../../app/types/CoachingType';
import { EmployeeType } from '../../../../app/types/EmployeeType';
import { SkePopover } from '../../../../common/components/Popover';
import { SkeModal } from '../../../../common/modals/generic/SkeModal';
import infoIcon from '../../../../images/icons/info-circle.svg';
import { RootState } from '../../../../setup';
import { coachingCategorySlice } from '../../coaching-categories.api';
import { coachingModalSlice } from '../../coaching-modal.slice';
import { Coaching } from '../../interfaces/coaching.model';
import { CoachingView } from "./ComponentModalContent/CoachingView";

interface CoachingDetails {
	category: string;
	comments?: string;
}

const coachingSchema = Yup.object().shape({});

interface Props {
	show: boolean;
	employee: Employee | EmployeeType | undefined;
	title: string;
	type: 'new' | 'edit' | 'view';
	coaching?: Coaching;
	refresh?: (updated: CoachingType) => void;
}

export function CoachingModal({
																show,
																employee,
																title,
																type,
																coaching,
																refresh,
															}: Props) {
	const token: string = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual) as string;
	const [coachingDate, setCoachingDate] = useState<Date>(new Date());
	const [loading, setLoading] = useState(false);
	const {data: categories} = coachingCategorySlice.useGetCoachingCategoriesQuery({});
	const [initValues, setInitValues] = useState<CoachingDetails>({
		category: coaching?.category?.id + '',
		comments: coaching?.comment,
	});
	const dispatch = useDispatch();

	const formik = useFormik<CoachingDetails>({
		enableReinitialize: true,
		initialValues: initValues,
		validationSchema: coachingSchema,
		onSubmit: (values) => {
			if (!coachingDate) {
				toast.error(`Please select a valid date`);
				throw new Error(`Invalid date found: ${coachingDate}`);
			}
			if (type === 'edit') {
				if (coaching) {
					updateCoaching(
						coaching?.id,
						{
							coachingCategoryId: parseInt(values.category),
							date: parseDateObjectToNewFormat(coachingDate, DateFormatUsingDateFns.PerfectDate),
							teamId: coaching.team?.id,
							comment: values.comments,
						},
						token,
					)
						.then((res) => {
							dispatch(coachingModalSlice.actions.hide(true));
							if (refresh) {
								refresh(res.data);
							}
						})
						.catch((err) => console.error(err));
				}
			}
			if (type === 'new') {
				if (employee) {
					if (employee.teams) {
						setLoading(true);
						createCoaching(
							{
								coachingCategoryId: parseInt(values.category),
								date: parseDateObjectToNewFormat(coachingDate, DateFormatUsingDateFns.PerfectDate),
								employeeId: employee?.id,
								teamId: employee?.teams[0]?.id,
								comment: values.comments,
							},
							token,
						)
							.then((res) => {
								setLoading(false);
								dispatch(coachingModalSlice.actions.hide(true));
								if (refresh) {
									refresh(res.data);
								}
							})
							.catch((err) => console.log(err));
					}
				}
			}
		},
	});

	const handleCoachingDate = (date: Date | null) => {
		if (!!date) {
			if (date < new Date()) {
				setCoachingDate(date);
			} else {
				toast.error('Please select a correct date', {
					position: 'top-right',
					theme: 'colored',
					autoClose: 3500,
				});
			}
		}
	};

	useEffect(() => {
		let abortController = new AbortController();

		setInitValues({
			category: coaching?.category?.id + '',
			comments: coaching?.comment,
		});

		if (coaching?.date) {
			setCoachingDate(parseYmdDatesToDateObject(coaching.date));
		}
		return () => {
			abortController.abort();
		};
	}, [
		coaching,
		token,
	]);

	const commentingTipsPopoverElement = <SkePopover
		trigger={['click']}
		placement="right-end"
		triggerElement={
			<button
				className="btn p-0 ms-1"
				type="button"
				aria-label="commenting tips">
				<img
					className="w-12px mb-1"
					src={infoIcon}
					alt="Icon" />
			</button>
		}
	>
		<div className="p-3">
			<h4 className="text-start text-muted">Pro Tips</h4>
			<ul className="text-start list-group list-group-flush">
				<li className="list-group-item px-0 text-muted">
					Trust: Ask questions! Inquisitive state of mind to understand their actions.
					This is an opportunity to build trust and your working relationship
				</li>
				<li className="list-group-item px-0 text-muted">
					Focus the conversation on the topic the individual or you would like to see
					improved
				</li>
				<li className="list-group-item px-0 text-muted">
					Action: What is the plan for action, when will you follow up, and what have they
					committed to do?
				</li>
				<li className="list-group-item px-0 text-muted">
					Follow up: When and how will you follow up? What does the future look and feel
					like when this goes well
				</li>
			</ul>
		</div>
	</SkePopover>;

	return (
		<SkeModal
			title={title}
			fullscreen="sm-down"
			show={show}
			backdrop={type === 'view' ? true : 'static'}
			onClose={() => dispatch(coachingModalSlice.actions.hide(true))}
			customHeaderContents={
				<>
					<h1 className="mb-3">{title}</h1>
					<div style={{maxWidth: '300px'}}>
						<EmployeeBadge
							employee={employee}
							size={AvatarSize.lg} />
					</div>
				</>
			}
			showFooter={false}
		>
			{type === 'view' && (
				<div className="modal-body mx-0 p-0">
					<CoachingView coaching={coaching}></CoachingView>
				</div>
			)}
			{(type === 'new' || type === 'edit') && (
				<form
					onSubmit={formik.handleSubmit}
					noValidate
					className="connection_form">
					<div className="card-body p-0">
						<div>
							<div>
								<div className="mb-4">
									<label htmlFor="recognition-add-date" className="form_label">
										Event Date
										<DatePicker
											inputClasses="w-100"
											labelClasses="fw-light fs-4"
											id="recognition-add-date"
											selectedDate={coachingDate}
											onChange={(d) => handleCoachingDate(d)}
											dateFormat="MMMM d, yyyy"
										/>
									</label>
								</div>
								<div className="mb-4">
									<label htmlFor="category" className="form_label">Category</label>
									<select
										id="category"
										className="form-select form-select-lg form_control"
										{...formik.getFieldProps('category')}
									>
										<option
											className="pe-3"
											value="">Select one
										</option>
										{categories?.length &&
											categories.map((category, i) => (
												<option
													className="pe-3"
													key={category.id}
													value={category.id}>
													{category.name}
												</option>
											))}
									</select>
									{formik.touched.category && formik.errors.category && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">{formik.errors.category}</div>
										</div>
									)}
								</div>
							</div>
						</div>

						<div className="row form_row mb-0">
							<div>
								<div className="d-inline-flex">
									<label htmlFor="comments" className="form_label">Comments</label>
									{commentingTipsPopoverElement}
								</div>
								<textarea
									rows={4}
									id="comments"
									className="ske-form-control form-control form-control-lg w-100 bg-transparent"
									style={{resize: 'none'}}
									{...formik.getFieldProps('comments')}
								></textarea>
								{formik.touched.comments && formik.errors.comments && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">{formik.errors.comments}</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="mt-5 card-footer d-flex justify-content-end border-0 p-0">
						<button
							type="submit"
							className="btn btn-primary d-flex align-items-center gap-1"
							disabled={loading || formik.values.category === 'undefined'}
						>
							{!loading && type === 'new' && (
								<>
									<i className="far fa-save"></i> Save
								</>
							)}
							{!loading && type === 'edit' && (
								<>
									<i className="fas fa-pen"></i> Update
								</>
							)}
							{loading && (
								<span
									className="indicator-progress"
									style={{display: 'block'}}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</form>
			)}
		</SkeModal>
	);
}
