import { get } from 'lodash';
import { UserModel } from '../../../app/models';

export const isAiReviewEnabled = (user: UserModel): boolean => {
	return !!get(user, 'company.settings.values', '');
};

export const isMassTextingEnabled = (user: UserModel): boolean => {
	return get(user, 'company.settings.communication.methods.SMS.bulk_send.enabled', false);
};
