import { SkeModal } from '../../../../common/modals/generic/SkeModal';
import { EmployeeBadge } from '../../../components/EmployeeBadge';
import { AttendanceType } from '../../../types/AttendanceType';
import { SkeInfoGrid } from '../../../../common/components/InfoGrid';
import { SkePopover } from '../../../../common/components/Popover';
import { DateFormatUsingDateFns } from '../../../modules/date/DateFormat.const';
import { parseDateObjectToNewFormat } from '../../../modules/date/utils/DateHelpers';
import { Icon } from '../../../components/icons/Icon';
import { CalendarX, Random } from '../../../components/icons/IconList';
import { CalendarDay } from '../../../components/icons/IconList';
import { CommentDots } from '../../../components/icons/IconList';
import { Calculator } from '../../../components/icons/IconList';
import { HourGlass } from '../../../components/icons/IconList';
import { UserCheck } from '../../../components/icons/IconList';
import { UserPlus } from '../../../components/icons/IconList';
import { FileFill } from '../../../components/icons/IconList';

interface Props {
	attendance: AttendanceType | null;
	modalId?: string;
	onClose?: () => void;
}

export function AttendanceViewModal({attendance, onClose}: Props) {
	const attendanceDateElement = (
		<SkePopover
			closeOnContentClick
			className="tooltop-auto-width"
			placement="top-end"
			trigger={['hover']}
			triggerElement={<div>{attendance?.date}</div>}
		>
			<div className="fw-bold d-flex gap-2 text-muted">
				<div className="d-flex align-items-center gap-1">
					<Icon
						classes="text-muted"
						icon={CalendarDay}
						type="svg"
						size="xs"
					/>
					<label
						className="fw-muted fs-8"
						htmlFor="created-at">
						Created At:
					</label>
				</div>
				<div
					className="fs-6 text-black fw-normal"
					id="created-at">
					{parseDateObjectToNewFormat(
						new Date(attendance?.created_at || ''),
						DateFormatUsingDateFns.PerfectDate,
					)}
				</div>
			</div>
		</SkePopover>
	)

	const getInfoGridProps = () => {
		return [
			{
				label: 'Occurrence',
				icon: <Icon
					classes="text-muted"
					icon={Random}
					type="svg"
					size="xs" />,
				value: attendance?.occurrence,
				columnSpan: 1,
			},
			{
				label: 'Date',
				icon: <Icon
					classes="text-muted"
					icon={CalendarDay}
					type="svg"
					size="xs" />,
				value: attendanceDateElement,
				columnSpan: 1,
			},
			{
				label: 'Reason',
				icon: <Icon
					classes="text-muted"
					icon={CommentDots}
					type="svg"
					size="xs" />,
				value: attendance?.reason.content,
				columnSpan: 1,
			},
			...(!attendance?.reason?.content.includes('FMLA')
				? [
					{
						label: 'Points',
						icon: <Icon
							classes="text-muted"
							icon={Calculator}
							type="svg"
							size="xs" />,
						value: attendance?.pointsAssigned,
						columnSpan: 1,
					},
				]
				: []),
			...(attendance?.reason?.content.includes('FMLA')
				? [
					{
						label: 'Hours',
						icon: <Icon
							classes="text-muted"
							icon={HourGlass}
							type="svg"
							size="xs" />,
						value: attendance?.fmla_hours,
						columnSpan: 1,
					},
				]
				: []),
			{
				label: 'Excusable',
				icon: <Icon
					classes="text-muted"
					icon={UserCheck}
					type="svg"
					size="xs" />,
				value: attendance?.reason.isExcused,
				columnSpan: 1,
			},
			{
				label: 'Created By',
				icon: <Icon
					classes="text-muted"
					icon={UserPlus}
					type="svg"
					size="xs" />,
				value: `${attendance?.creator.firstName} ${attendance?.creator.lastName}`,
				columnSpan: 1,
			},
			{
				label: 'Detail',
				icon: <Icon
					classes="text-muted"
					icon={FileFill}
					type="svg"
					size="xs" />,
				value: attendance?.supervisorNote || 'No Details Provided',
				columnSpan: 2,
			},
		];
	};

	const gridInfo = getInfoGridProps();

	return (
		<SkeModal
			title=""
			show={true}
			showFooter={false}
			backdrop={true}
			customHeaderContents={
				<div className="d-flex flex-column gap-3">
					<div className="d-flex gap-2 align-items-center">
						<Icon
							classes="text-black"
							icon={CalendarX}
							type="svg"
							size="md" />
						<h1>Attendance</h1>
					</div>
					<div style={{maxWidth: '300px'}}>
						<EmployeeBadge
							employee={attendance?.employee}
							team={attendance?.team} />
					</div>
				</div>
			}
			onClose={() => {
				if (onClose) onClose();
			}}
		>
			<SkeInfoGrid items={gridInfo}></SkeInfoGrid>
		</SkeModal>
	);
}
