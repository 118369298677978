import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { TranslateIcon } from '../../components/icons/TranslateIcon';
import { SupportedLanguageEnum, SupportedLanguageReadableEnum } from './models/Language.model';

export interface Props {
	availableLanguages: SupportedLanguageEnum[];
	fromLanguage: SupportedLanguageEnum;
	toLanguage: SupportedLanguageEnum;
	onChange: (fromLanguage: SupportedLanguageEnum, toLanguage: SupportedLanguageEnum) => void;
}

export function TranslateSelector(props: Props) {
	const [fromLanguage, setFromLanguage] = useState<SupportedLanguageEnum>(props.fromLanguage);
	const [toLanguage, setToLanguage] = useState<SupportedLanguageEnum>(props.toLanguage);
	const [showSelector, setShowSelector] = useState<boolean>(false);

	const handleToggleShowSelector = () => {
		setShowSelector(!showSelector);
	};
	const handleClear = () => {
		setShowSelector(false);
		setFromLanguage(props.fromLanguage);
		setToLanguage(props.fromLanguage);
		props.onChange(fromLanguage, fromLanguage);
	};

	const handleApply = () => {
		setShowSelector(false);
		props.onChange(fromLanguage, toLanguage);
	};

	return (
		<>
			<button
				data-bs-toggle="collapse"
				data-bs-target="#language-selector"
				type="button"
				onClick={handleToggleShowSelector}
				className="btn btn-link w-auto p-0">
				<TranslateIcon
					title="Click to see translation options" />
				Change translation settings
				<span className="text-muted">
					{props.fromLanguage === props.toLanguage && ' (none)'}
					{props.fromLanguage !== props.toLanguage && ` (${SupportedLanguageReadableEnum[props.fromLanguage]} to ${SupportedLanguageReadableEnum[props.toLanguage]})`}
				</span>

			</button>

			<div
				id="language-selector"
				className={clsx('accordion-collapse my-3', {
					collapse: !showSelector,
					show: showSelector,
				})}>
				<div className="d-inline-flex flex-row gap-3 align-items-center">
				<div
					className="btn-group-sm btn-group-vertical"
					role="radiogroup"
					aria-label="Change text translation settings">
					<span className="fs-5">From: </span>
					{props.availableLanguages.map((lng, i) => (
						<Fragment key={i}>
							<input
								type="radio"
								name="from-language"
								id={`from-language-${lng}`}
								autoComplete="off"
								checked={lng === fromLanguage}
								onChange={() => setFromLanguage(lng)}
								className="btn-check" />
							<label
								className="btn btn-group-sm btn-outline-primary btn-outline"
								htmlFor={`from-language-${lng}`}>{SupportedLanguageReadableEnum[lng]}</label>
						</Fragment>
					))}
				</div>
				<div
					className="btn-group-sm btn-group-vertical"
					role="radiogroup"
					aria-label="Change text translation settings">
					<span className="fs-5">To: </span>
					{props.availableLanguages.map((lng, i) => (
						<Fragment key={i}>
							<input
								type="radio"
								name="to-language"
								id={`to-language-${lng}`}
								autoComplete="off"
								checked={lng === toLanguage}
								onChange={() => setToLanguage(lng)}
								className="btn-check" />
							<label
								className="btn btn-group-sm btn-outline-primary btn-outline"
								htmlFor={`to-language-${lng}`}>{SupportedLanguageReadableEnum[lng]}</label>
						</Fragment>
					))}
				</div>
				<div className="d-inline-flex flex-column">
					{/*<div className="ms-auto">*/}
						<button
							type="button"
							onClick={handleClear}
							className="btn btn-sm btn-outline-danger">
							Clear
						</button>
						<button
							type="button"
							onClick={handleApply}
							className="btn btn-sm btn-outline-primary">
							Apply
						</button>
					{/*</div>*/}
				</div>
			</div>

			</div>
		</>
	);
}
