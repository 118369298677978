import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { get } from 'lodash';
import { ApiResponse } from '../../app/modules/shared/models/ApiResponse.model';
import { getAndSetBearerToken } from '../../common/api/common.api';
import { SkeResponseUtilBaseWithResults } from '../../common/interfaces/response-format.model';
import {
	CommunicationPrecheckResponse,
	CommunicationTagTypes,
	CreateCommunicationApiRequestModel,
	GetMassCommunicationApiRequestModel,
	CommunicationCategoryModel,
	CommunicationCreateResponse,
	CommunicationFetchModel,
	CommunicationGetOneResponseResults,
	CommunicationRetryRequestModel,
	CommunicationRetryResponse,
} from './models/communication.model';

export const communicationSlice = createApi({
	reducerPath: 'communicationApi',
	tagTypes: [
		CommunicationTagTypes.CommunicationCategories,
		CommunicationTagTypes.Communication,
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}/comms`,
		prepareHeaders: getAndSetBearerToken,
	}),
	endpoints: (builder) => ({
		getMassCommunicationPrecheck: builder.query<CommunicationPrecheckResponse, GetMassCommunicationApiRequestModel>({
			query: (args) => {
				return {
					url: '/precheck/sms',
					method: 'GET',
					params: args,
				};
			},
			transformResponse: (response: SkeResponseUtilBaseWithResults<CommunicationPrecheckResponse>) => response.results,
			providesTags: (results, error, id) => {
				const categories: CommunicationCategoryModel[] = get(results, 'categories', []);
				// not handling users yet
				if (categories) {
					return [
						...categories.map(({id}) => ({
							type: CommunicationTagTypes.CommunicationCategories,
							id,
						} as const)),
						{
							type: CommunicationTagTypes.CommunicationCategories,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: CommunicationTagTypes.CommunicationCategories,
						id: 'LIST',
					},
				];
			},
		}),
		createMassCommunication: builder.mutation<CommunicationCreateResponse, CreateCommunicationApiRequestModel>({
			query: (args) => ({
				url: 'sms',
				method: 'POST',
				body: args,
			}),
			transformResponse: (response: SkeResponseUtilBaseWithResults<CommunicationCreateResponse>) => response.results,
			invalidatesTags: [
				{
					type: CommunicationTagTypes.Communication,
					id: 'LIST',
				},
			],
		}),
		getMassCommunications: builder.query<CommunicationFetchModel[], GetMassCommunicationApiRequestModel>({
			query: (args) => {
				return {
					url: '',
					method: 'GET',
					params: args,
				};
			},
			transformResponse: (response: ApiResponse<CommunicationFetchModel>) => {
				return response.items;
			},
			providesTags: (result, error, id) => {
				if (result) {
					return [
						// @ts-ignore
						...result.map(({id}) => ({
							type: CommunicationTagTypes.Communication,
							id: id,
						} as const)),
						{
							type: CommunicationTagTypes.Communication,
							id: 'LIST',
						},
					];
				}
				return [
					{
						type: CommunicationTagTypes.Communication,
						id: 'LIST',
					},
				];
			},
		}),
		getOneMassCommunication: builder.query<CommunicationGetOneResponseResults, {id: number}>({
			query: (args: {id: number}) => {
				let url = `${args.id}`;
				return {
					url,
				};
			},
			transformResponse: (response: SkeResponseUtilBaseWithResults<CommunicationGetOneResponseResults>) => response.results,
			providesTags: (results: any, error, args) => {
				if (results.comm) {
					return [
						{
							type: CommunicationTagTypes.Communication,
							id: args.id,
						},
					];
				}
				return [];
			},
		}),
		retryMassCommunication: builder.mutation<CommunicationRetryResponse, CommunicationRetryRequestModel>({
			query: (args) => ({
				url: `/retry/${args.id}`,
				method: 'PATCH',
				body: args,
			}),
			transformResponse: (response: SkeResponseUtilBaseWithResults<CommunicationRetryResponse>) => {
				return response.results;
			},
			invalidatesTags: (result, error, arg) => {
				if (result?.comm) {
					return [
						{
							type: CommunicationTagTypes.Communication,
							id: arg.id,
						},
						{
							type: CommunicationTagTypes.Communication,
							id: 'LIST',
						},
					];
				}
				return [];
			},
		}),
	}),
});
